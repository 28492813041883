<template>
  <div>
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'CoreStep',
    props: {
      step: {type: String, required: true}
    }
  }
</script>
