<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>{{ teamUid ? 'Édition d\'un groupe' : 'Création d\'un groupe' }}</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar :title="teamUid ? 'Édition d\'un groupe' : 'Création d\'un groupe'" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2">
        <core-stepper v-model="step">
          <core-step v-if="$auth.granted('role_professionals')" step="select_type">
            <v-row class="justify-center">
              <v-col cols="12" md="6">
                <v-card outlined class="pa-2" :height="$vuetify.breakpoint.mdAndDown ? 'auto' : '400'">
                  <v-img src="@/assets/img/illustrations/team-patient.svg" class="ma-auto" :height="$vuetify.breakpoint.mdAndDown ? '130' : '200'" />
                  <v-card-title class="pt-md-0 pb-1">Orienté patient</v-card-title>
                  <v-card-text>
                    Un groupe orienté patient est un espace de travail regroupant des professionnels, centralisé sur un
                    patient défini
                  </v-card-text>
                  <v-card-actions>
                    <v-btn x-small color="success" class="mx-auto" @click="step = 'select_patient'">selectionner</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card outlined class="pa-2" :height="$vuetify.breakpoint.mdAndDown ? 'auto' : '400'">
                  <v-img src="@/assets/img/illustrations/team-pros.svg" class="ma-auto" :height="$vuetify.breakpoint.mdAndDown ? '130' : '200'" />
                  <v-card-title class="pt-md-0 pb-1">Groupe standard</v-card-title>
                  <v-card-text>
                    Un groupe standard est un espace de travail regroupant des professionnels sans orientation ciblée
                  </v-card-text>
                  <v-card-actions>
                    <v-btn x-small color="success" class="mx-auto" @click="step = 'fill_form'">selectionner</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </core-step>

          <core-step v-else step="select_type">
            <v-row justify="center">
              <v-col cols="12" align-self="center">
                <v-card outlined class="pa-2">
                  <v-img src="@/assets/img/illustrations/team-pros.svg" class="ma-auto" height="200" />
                  <v-card-title class="pb-1">Nouveau groupe</v-card-title>
                  <v-card-text>
                    Un groupe vous permet de dialoguer et partager autour d'un sujet avec d'autres patients ou aidant.
                    Une fois créé vous pouvez inviter des patients à rejoindre votre groupe.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn x-small color="success" class="mx-auto" @click="step = 'fill_form'">J'ai compris créer mon groupe</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </core-step>

          <core-step step="select_patient">
            <v-card flat class="p-rel">
              <form-category title="Groupe orienté patient" subtitle="Veuillez séléctionner le patient concerné"
                             class="mt-4"
              />
              <div>
                <patient-autocomplete v-model="model.patient" class="my-10 px-1" />
              </div>

              <v-card-actions class="justify-center">
                <v-btn x-small class="mx-1" @click="goFirstStep">Retour</v-btn>
                <v-btn x-small :disabled="!model.patient" class="mx-1" color="primary" @click="step = 'fill_form'">
                  Continuer
                </v-btn>
              </v-card-actions>
            </v-card>
          </core-step>

          <core-step step="fill_form">
            <v-form ref="form" lazy-validation autocomplete="off" class="mt-4" @submit.prevent="submit">
              <v-card flat>
                <form-category title="Informations générales" subtitle="Identité et dénomination du groupe" />
                <div v-if="model.patient" class="text-body-2 font-italic op-80">
                  Autour du patient : {{ model.patient.full_name }}
                </div>
                <v-text-field v-model="model.name" label="Nom du groupe" :rules="[rules.required]" required />
                <v-textarea v-model="model.description" maxlength="255" label="Description du groupe" />
                <v-card-actions class="justify-center">
                  <v-btn v-if="!teamUid" x-small class="mr-1" @click="goFirstStep">Retour</v-btn>
                  <v-btn color="primary" x-small type="submit" :loading="submitting" :disabled="submitting">
                    {{ teamUid ? 'Éditer le groupe' : 'Créer le groupe' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </core-step>
        </core-stepper>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import PatientAutocomplete from '@/modules/patients/components/PatientAutocomplete'
  import {rules} from '@/services/rules'
  import CoreStepper from '@/modules/core/components/CoreStepper'
  import CoreStep from '@/modules/core/components/CoreStep'
  import {mapActions} from 'vuex'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import {Patient, Team} from '@/models'

  export default {
    name: 'TeamForm',
    components: {
      FormCategory,
      CoreStep,
      CoreStepper,
      PatientAutocomplete,
      ModalsSidebar
    },
    props: {
      patientUid: String,
      teamUid: String
    },
    data() {
      return {
        step: this.patientUid || this.teamUid ? 'fill_form' : 'select_type',
        rules,
        team: null,
        model: {
          patient: this.patientUid ? Patient.get(this.patientUid) : null,
          name: null,
          description: null
        },
        contactsInvited: [],
        submitting: false
      }
    },
    async mounted() {
      if (this.teamUid) {
        this.team = await Team.fetchOne(this.teamUid)

        this.model.name= this.team.name
        this.model.description = this.team.description
      }
    },
    methods: {
      ...mapActions('teams', ['create']),
      goFirstStep() {
        this.step = 'select_type'
        this.model.patient = this.patientUid ? Patient.get(this.patientUid) : null
      },
      async submit() {
        this.submitting = true

        if (this.teamUid) {
          try {
            await this.team.update(this.model)
          } catch (e) {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue',
              subtitle: 'Mon groupe n\'a pas pu etre édité.'
            })
            throw e
          } finally {
            this.$emit('close')
            this.submitting = false
          }
        } else {
          try {
            let team = await this.create({
              ...this.model,
              patient: this.model.patient ? this.model.patient.uid : null
            })
            await this.$router.push({name: 'team-profile', params: {uid: team.uid}})
          } catch (e) {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue',
              subtitle: 'Mon groupe n\'a pas pu etre créé.'
            })

            throw e
          } finally {
            this.$emit('close')
            this.submitting = false
          }
        }
      }
    }
  }
</script>
